
<template>
  <div class="center">
    <b-modal id="bv-login-modal" v-model="show" size="xl" >
      <template #modal-title>
        <h4 class="not-margin">
          User record
        </h4>
      </template>
      <div class="con-form">

        <label for="form-user-code">Users Name</label>
        <b-input-group size="sm" class="mb-2">

          <b-form-input
            id="form-user-code"
            v-model="$v.form.name.$model"
            placeholder="Name"
            :state="validateState('name')"
            required
          ></b-form-input>
        </b-input-group>        

        <label for="form-user-code">User PIN Code</label>
        <b-input-group size="sm" class="mb-2">

          <b-form-input
            id="form-user-code"
            v-model="$v.form.user_code.$model"
            placeholder="PIN Code"
            type="number"
            :state="validateState('user_code')"
            required
          ></b-form-input>
        </b-input-group>        

        <label for="form-user-code">Type of User</label>
        <b-input-group size="sm" class="mb-2">
            <b-form-select v-model="$v.form.level.$model" :options="levels" size="sm" :state="validateState('level')" class="form-control"></b-form-select>
        </b-input-group>        


      </div>

      <template #modal-footer>
        <div class="footer-dialog">
          <b-button block @click="handleSubmit">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>

  import { validationMixin } from 'vuelidate';
  import { required, minLength} from "vuelidate/lib/validators";

  import defaults from '@/plugins/defaults';  


  export default {
    mixins: [validationMixin], 
    props: {
      showdialog: Boolean,
      record:Object,
    },   
    data() {
      return {
        levels:defaults.LEVELS,
        form: {
          id: -1,
          user_code: '',
          name: '',
          pin: '',
          level: 'user',
        }
      }
    },   
    validations: {
      form: {
        user_code: {
          required,
          minLength: minLength(3)
        },
        name: {
          required,
        },
        level: {
          required
        },
      }
    },       
    methods: {
      pinText() {
        if (this.form.id < 0) {
          return "Pin"
        } else {
          return "Pin ( Leave blank to not change it )"
        }
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },      
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()

        console.log(valid)

        this.terminalIdState = valid
        return valid
      },      
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },      
      handleSubmit() {
        this.$v.form.$touch();
        console.log(this.$v.form);
        if (this.$v.form.$anyError) {
          return;
        }

        this.form.pin = this.form.user_code;
        this.$emit('onSaveClick', this.form)
        // Hide the modal manually

      }
    },
    computed: {
      show: {
        get () {
          return this.showdialog
        },
        set() {
        }
      },
    },
    watch: {
        record: function() {
            this.form = this.record;
        }, 
    }     
  }
</script>

<style>
  .modal-header .close {
    display:none;
  }  
</style>