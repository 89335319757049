<template>

    <div class="headerholder">
        <b-row>
            <b-col cols="3" class="headerFixButtons">

            </b-col>
            <b-col cols="6">
                <h1 class="headerSystemText"><img src="../../assets/oeno-logo.png" style="height:2vw;margin-top:-5px;"/> {{SystemName}} <span class="headerVersionText">v{{SystemVersion}}</span></h1>
                
            </b-col>
            <b-col cols="3">

                <b-row>

                    <b-col w="2">
                    </b-col>
                    <b-col w="2">
                    </b-col>
                    <b-col w="2">
                    </b-col>
                    <b-col w="2">

                    </b-col>
                    <b-col w="2" >
                        <div class="headerIconHolder">
                            <router-link to="/admin"><font-awesome-icon icon="cogs" class="headericon" /></router-link>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="headerIconHolder">
                            <router-link to="/"><font-awesome-icon icon="home" class="headericon" /></router-link>
                        </div>                        
                    </b-col>
                </b-row>

            </b-col>
        </b-row>    
    </div>

</template>

<script>

  export default {
    name: 'headersection',      
    data () {  
        return {
            SystemName:process.env.VUE_APP_NAME,
            SystemVersion:process.env.VUE_APP_VERSION,
        }
    },
   
  }

</script>



<style>

   .headerButtonSwitch,.headerSearch {
       width:100%;
       padding-top:0.5vw;
       padding-left:0.4vw;
       padding-right:0;
       padding-bottom: 0.8vw;

   }


    .headerButtonSwitchActive {
        background-color:#b0996f !important;
        color:white !important;
        border-color: #b0996f !important;
        font-size: 1rem !important;
    }

    .headerButtonSwitchDisabled {
        background-color: #2e3444 !important;
        color:#7f838e !important;
        border-color: #2e3444 !important;
        font-size: 1rem !important;
    }

    .headerSearchInput {
        background-color: #2e3444 !important;
        color:#9a9da7 !important;
        border-color: #2e3444 !important;     
    }

    .headerSearchInputIcon .input-group-text {
         background-color: #2e3444 !important;       
         border: 1px solid #2e3444;

    }

    .headerSearchInputIconIcon {
        color:#9a9da7 !important;
        height: 1.5rem;       
    }

    .headerSystemText {
        color:#7f838e;
        margin-bottom: 0.8vw;
        margin-top: 0.8vw;
        margin-left: 2vw;
    }

    .headerVersionText {
        font-size: 0.6vw;
        color:#7f838e;
    }

    .headerOrderText {
        color:white;
        margin-top: 0.8vw;
        margin-left: 2vw;        
    }
    .headerOrderTextSmall {
        font-size:1rem;
        color:#9a9da7
    }

    .headerIconHolder {
       padding:0.5vw;
       cursor:pointer;
    }
    .headericon {
        font-size: 2vw;
        color:white;
    }
    .headerFixButtons {
        min-width: 24.5vw;
        width: 24.5vw !important;
        padding-right:0;
    }

</style>

