const COLORS = [
    {back:'#fffb91',color:'#000000', name:'Cheese Color'},
    {back:'#ffa691',color:'#000000', name:'Charcuterie Color'},
    {back:'#69661b',color:'#ffffff', name:'Olive Color'},
    {back:'#af9971',color:'#ffffff', name:'Nuts Color'},
    {back:'#eddab8',color:'#00000', name:'Bread Color'},
    {back:'#5351fb',color:'#ffffff', name:'Soft Drink Color'},
    {back:'#4e34a3',color:'#ffffff', name:'Coffee Color'},
    {back:'#8c0034',color:'#ffffff', name:'Red Wine Color'},
    {back:'#F9CF73',color:'#000000', name:'White Wine Color'},
    {back:'#F3EDA3',color:'#000000', name:'Champagne Color'},
    {back:'#F7CCB0',color:'#000000', name:'Rose Color'},
  ];
  const SIZES = [
    {width:'4',height:'4', name: '4x4'},
    {width:'4',height:'1', name: '4x2'},
    {width:'2',height:'2', name: '2x2'},
  ];
  const LEVELS = [
    {value:'admin',text:'Admin'},
    {value:'user',text:'User'},
  ];

export default {
    COLORS: COLORS,
    SIZES: SIZES,
    LEVELS: LEVELS,
}

